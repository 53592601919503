// Main container for medals
.medals-container {
    background-color: white;
    border-radius: 1rem;
    margin: 0 auto; // Centering the container
    margin-top: 25vh;
    margin-bottom: 10vh;
    overflow-x: auto;
    overflow-y: auto;
    padding: 20px; // Added padding for better spacing
}

// List of medals and its elements
.medals-list {
    max-width: 75rem;
    width: 100%;
    margin: 2.5rem auto; // Center the list within container

    .medals-title {
        margin: 0;
        margin-bottom: 1rem;
        padding: 0;
        font-size: 36px;
        color: #1A1A1A;

        @media (max-width: 768px) {
            font-size: 24px; // Smaller font size for smaller screens
        }
    }

    .medals-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        th, td {
            border: 1px solid #ccc;
            padding: 8px;
            text-align: left;
        }

        th {
            background-color: #f4f4f4;
        }

        tr:nth-child(even) {
            background-color: #f9f9f9;
        }
    }

	button {
		padding: 0.5rem;
	}
}

// Notes related to medals
.medals-notes {
    h3 {
        margin: 0;
        margin-top: 2.5rem;
        padding: 0;
        font-size: 36px;
        color: #1A1A1A;

        @media (max-width: 768px) {
            font-size: 24px;
        }
    }

    margin: 2.5rem;
    font-size: 16px;
    text-align: justify;
    text-justify: inter-word;
    max-width: 75rem;
    font-family: 'Open Sans', sans-serif;
    color: #1A1A1A;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}
