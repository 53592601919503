.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: #333; // Dark grey text for better readability
}

select, input {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;

    @media (max-width: 768px) {
        margin: 8px;
        padding: 8px;
    }
}
