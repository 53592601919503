.app-header {
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden; // Keeps all content within the header

    .header-container {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1rem 20px; // Uniform padding that adjusts on smaller screens
        max-width: 75rem;

        .logo-container {
            text-decoration: none;
            display: flex;
            align-items: center; // Ensures vertical alignment
            padding: 0;
            flex-grow: 1; // Ensures the logo takes the necessary space

            img {
                height: auto;
                width: auto;
                max-width: 100%;
                max-height: 80px; // Standard height for all devices

                @media (max-width: 768px) {
                    max-height: 60px; // Adjusts height for smaller screens
                }
            }
        }

        .header-title {
            font-family: 'Titillium Web', sans-serif;
            font-size: 28px;
            color: #000;
            white-space: nowrap; // Ensures title remains on one line
            padding: 0;

            @media (max-width: 768px) {
                font-size: 20px; // Smaller font size on smaller screens
                padding-right: 10px; // Adjusts padding to prevent text from touching the edge
            }
        }
    }
}
