body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://www.defence.gov.au/themes/custom/custom/defence8ui/images/bg-pattern-grey.svg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: repeat;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 400;
	line-height: 1.2;
	color: #000;
}

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400&family=Open+Sans:wght@300&display=swap');